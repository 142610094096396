import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import HJ from "../assests/SliderImg/pd1.png";
import NA from "../assests/SliderImg/pd2.png";
import lk from "../assests/SliderImg/pd3.png";
import './Slider.css';
import { useMediaQuery } from 'react-responsive';

function CarouselComponent() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);
  const isLargeScreen = useMediaQuery({ minWidth: 768 });

  return (
    
    <div className="carousel-container">
      <Carousel activeIndex={index} onSelect={handleSelect} id="Home-section" >
        <Carousel.Item >
          <img
           className={`d-block w-100 ${isLargeScreen ? 'pc-only-image' : ''}`}
            src={HJ}
            alt="First slide"
          />
          <Carousel.Caption style={{ display: "flex", justifyContent: "center", gap: "140px", marginBottom: "40px" }}>
  <div style={{ width: "160px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.3)", borderRadius: "20px", backdropFilter: "blur(3px)", padding: "4px" }}>
    <p style={{ fontSize: "25px", marginBottom: "5px", color: "#fff" }}>7</p>
    <p style={{ marginTop: "0", marginBottom: "0", color: "#fff", fontWeight: "100px" }}>+ Years </p>
    {/* Add a button here */}
   
  </div>
  <div style={{ width: "160px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.3)", borderRadius: "20px", backdropFilter: "blur(5px)", padding: "4px" }}>
    <p style={{ fontSize: "25px", marginBottom: "5px", color: "#fff" }}>9.8</p>
    <p style={{ marginTop: "0", marginBottom: "0", color: "#fff" }}>Client Rating</p>
    {/* Add a button here */}
   
  </div>
  <div style={{ width: "160px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.3)", borderRadius: "20px", backdropFilter: "blur(5px)", padding: "4px" }}>
    <p style={{ fontSize: "25px", marginBottom: "5px", color: "#fff" }}>3000+</p>
    <p style={{ marginTop: "0", marginBottom: "0", color: "#fff" }}>Students</p>
    {/* Add a button here */}
   
  </div>
</Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
         className={`d-block w-100 ${isLargeScreen ? 'pc-only-image' : ''}`}
            src={NA}
            alt="Second slide"
            // style={{ height: "850px", objectFit: "cover" }}
          />
        <Carousel.Caption style={{ display: "flex", justifyContent: "center", gap: "140px", marginBottom: "40px" }}>
  <div style={{ width: "160px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.3)", borderRadius: "20px", backdropFilter: "blur(3px)", padding: "4px" }}>
    <p style={{ fontSize: "25px", marginBottom: "5px", color: "#fff" }}>7</p>
    <p style={{ marginTop: "0", marginBottom: "0", color: "#fff", fontWeight: "100px" }}>+ Years </p>
    {/* Add a button here */}
   
  </div>
  <div style={{ width: "160px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.3)", borderRadius: "20px", backdropFilter: "blur(5px)", padding: "4px" }}>
    <p style={{ fontSize: "25px", marginBottom: "5px", color: "#fff" }}>9.8</p>
    <p style={{ marginTop: "0", marginBottom: "0", color: "#fff" }}>Client Rating</p>
    {/* Add a button here */}
   
  </div>
  <div style={{ width: "160px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.3)", borderRadius: "20px", backdropFilter: "blur(5px)", padding: "4px" }}>
    <p style={{ fontSize: "25px", marginBottom: "5px", color: "#fff" }}>3000+</p>
    <p style={{ marginTop: "0", marginBottom: "0", color: "#fff" }}>Students</p>
    {/* Add a button here */}
   
  </div>
</Carousel.Caption>

        </Carousel.Item>
        <Carousel.Item>
          <img
           className={`d-block w-100 ${isLargeScreen ? 'pc-only-image' : ''}`}
            src={lk}
            alt="Third slide"
         
            
          />
          <Carousel.Caption style={{ display: "flex", justifyContent: "center", gap: "140px", marginBottom: "40px" }}>
  <div style={{ width: "160px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.3)", borderRadius: "20px", backdropFilter: "blur(3px)", padding: "4px" }}>
    <p style={{ fontSize: "25px", marginBottom: "5px", color: "#fff" }}>7</p>
    <p style={{ marginTop: "0", marginBottom: "0", color: "#fff", fontWeight: "100px" }}>+ Years </p>
    {/* Add a button here */}
   
  </div>
  <div style={{ width: "160px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.3)", borderRadius: "20px", backdropFilter: "blur(5px)", padding: "4px" }}>
    <p style={{ fontSize: "25px", marginBottom: "5px", color: "#fff" }}>9.8</p>
    <p style={{ marginTop: "0", marginBottom: "0", color: "#fff" }}>Client Rating</p>
    {/* Add a button here */}
   
  </div>
  <div style={{ width: "160px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.3)", borderRadius: "20px", backdropFilter: "blur(5px)", padding: "4px" }}>
    <p style={{ fontSize: "25px", marginBottom: "5px", color: "#fff" }}>3000+</p>
    <p style={{ marginTop: "0", marginBottom: "0", color: "#fff" }}>Students</p>
    {/* Add a button here */}
   
  </div>
</Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default CarouselComponent;
