import React from "react";
import { Heading, Text, Flex, Container } from "@chakra-ui/react";
import { motion } from "framer-motion";

function PrivacyPolicy() {
  return (
    <motion.div
        
    initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: "auto" }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ duration: 0.9 }}
  >
    <Container
      maxW="full"
      // height="1200px"
      style={{
        transition: "all 0.9s",
        background: "linear-gradient(to right, #bdc3c7, #2c3e50)",
        fontFamily: "Nunito Sans",
        maxWidth: "full",
      }}
    >
      <Flex direction="column" color="white" p={8}>
        <Heading fontWeight={400} textAlign="center">
          Privacy Policy
        </Heading>

        <Text fontSize="lg" mt={4} textAlign="start">
          <strong>1. Introduction</strong>
          <br />
          Welcome to Acadroof's Privacy Policy. This policy outlines how we
          collect, use, and protect your personal information when you engage
          with our services. By accessing or using our services, you agree to
          the practices described herein.
        </Text>

        <Text fontSize="lg" mt={4} textAlign="start">
          <strong>2. Information We Collect</strong>
          <br />
          We may collect the following types of information:
          <br />
          - Personal Information: This includes your name, postal address, email
          address, phone number, and any other information you provide when
          using our services.
          <br />- Usage Data: We collect information about how you interact with
          our website and services, including IP addresses, browser types,
          device information, and pages visited.
        </Text>

        <Text fontSize="lg" mt={4} textAlign="start">
          <strong>3. How We Use Your Information</strong>
          <br />
          We may use your information for the following purposes:
          <br />
          - Providing Services: To fulfill assignments, projects, exams,
          quizzes, and other educational tasks as requested by you.
          <br />
          - Communication: To communicate with you regarding your orders,
          updates, and relevant information related to our services.
          <br />- Improving Our Services: To enhance our services, analyze usage
          patterns, and conduct research to provide you with a better
          experience.
        </Text>

        <Text fontSize="lg" mt={4} textAlign="start">
          <strong>4. Security</strong>
          <br />
          We take reasonable steps to protect your personal information. We
          employ industry-standard security measures, but it's important to
          understand that no online platform can guarantee complete security. We
          continually monitor and update our security protocols to safeguard
          your data.
        </Text>

        <Text fontSize="lg" mt={4} textAlign="start">
          <strong>5. Data Retention</strong>
          <br />
          We will retain your personal information only for as long as necessary
          to fulfill the purposes for which it was collected and in compliance
          with applicable laws and regulations.
        </Text>
        <Text fontSize="lg" mt={4} textAlign="start">
  <strong>6. Cookies and Tracking Technologies</strong>
  <br />
  We may use cookies and similar tracking technologies to enhance your browsing experience on our website. These technologies help us analyze usage patterns, customize content, and provide you with personalized recommendations. You can manage your cookie preferences through your browser settings.
</Text>

<Text fontSize="lg" mt={4} textAlign="start">
  <strong>7. Third-Party Services</strong>
  <br />
  We may use third-party services and tools to improve our website and services. These third parties may have their own privacy policies, and we encourage you to review them. We are not responsible for the practices of third-party services.
</Text>

<Text fontSize="lg" mt={4} textAlign="start">
  <strong>8. Information Sharing</strong>
  <br />
  We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted partners and service providers who assist us in delivering our services. We ensure that they adhere to strict privacy and security standards.
</Text>

<Text fontSize="lg" mt={4} textAlign="start">
  <strong>9. Your Choices</strong>
  <br />
  You have the right to access, update, or delete your personal information. You can contact us to exercise these rights or if you have any concerns about your data. We will respond to your requests in accordance with applicable data protection laws.
</Text>

<Text fontSize="lg" mt={4} textAlign="start">
  <strong>10. Updates to the Privacy Policy</strong>
  <br />
  We may update our Privacy Policy to reflect changes in our practices and legal requirements. When we make significant updates, we will notify you through our website or other communication channels. We encourage you to review the Privacy Policy periodically.
</Text>

<Text fontSize="lg" mt={4} textAlign="start">
  <strong>11. Contact Information</strong>
  <br />
  If you have any questions, concerns, or requests related to your privacy or this Privacy Policy, please contact us at [Your Contact Information].
</Text>

      </Flex>
    </Container>
    </motion.div>
  );
}

export default PrivacyPolicy;
