import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import "./Serve.css";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";

function Services({
  commonHeading,
  commonParagraph,
  servicesData, // Pass the array of service data as a prop
}) {
  return (
    <Box textAlign="center" mt="50px">
      <Box as="div" fontSize="38px" fontWeight="bold" mb="20px"  style={{ fontFamily: "Nunito Sans" }}>
        {commonHeading}
      </Box>
      <p style={{ fontSize: "14px" }}>{commonParagraph}</p>
      {/* <motion.div
       initial={{ x: 100, opacity: 0 }} // Start from the right with opacity 0
       animate={{ x: 0, opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } }} // Slide in and fade in
       exit={{ x: -100, opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } }} // Slide out and fade out
      > */}
      {servicesData.map((service, index) => (
        <Container key={index}>
          <Flex
           style={{ fontFamily: "Nunito Sans" }}
            direction={{
              base: "column",
              md:
                index === 1 || index === 3 || index === 5
                  ? "row-reverse"
                  : "row",
            }}
            alignItems="center"
            justifyContent="space-between"
            mb="20px"
            flexWrap="wrap" // Allow items to wrap on smaller screens
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems={
                index === 1 || index === 3 || index === 5
                  ? "flex-end"
                  : "flex-start"
              }
              mr={{
                base: "0",
                md: index === 1 || index === 3 || index === 5 ? "20px" : "0",
              }}
              p="20px" // Adjust padding for smaller screens
              bg="white" // Add background color if desired
              borderRadius="8px" // Add border radius for styling
              width={{ base: "100%", md: "45%" }} // Adjust width on smaller screens
              mb={{ base: "20px", md: "0" }} // Add margin for spacing
            >
              <img
                src={index % 2 === 0 ? service.imageLeft : service.imageRight}
                alt={service.imageLeftDescription}
                style={{ maxWidth: "60px", marginBottom: "20px" }}
              />
              <Box
                as="div"
                fontSize="26px"
                fontWeight="550"
                textAlign="start"
                mb="5px"
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <div
                    style={{
                      height: "40px",
                      width: "10px",
                      background: "rgba(47, 154, 230, 1)",
                      marginRight: "10px",
                    }}
                  ></div>{" "}
                  <p className="mb-0">{service.leftImageHeading}</p>
                </Box>
              </Box>
              {service.leftImageParagraph
                .split("\n")
                .map((paragraph, paragraphIndex) => (
                  <p
                    key={paragraphIndex}
                    style={{
                      textAlign: "justify",
                      fontSize: "16px",
                      lineHeight: "1.5",
                    }}
                    className="paragraph-style"
                  >
                    {paragraph}
                  </p>
                ))}
            </Box>
            <Box
              display="flex"
              mt={100}
              justifyContent="center"
              alignItems="center"
              width={{ base: "100%", md: "45%" }} // Adjust width on smaller screens
            >
              <img
                src={index % 2 === 0 ? service.imageRight : service.imageLeft}
                alt="Right Service Illustration"
                style={{ maxWidth: "100%" }} // Adjust image width
              />
            </Box>
          </Flex>
        </Container>
      ))}
       {/* </motion.div> */}
    </Box>
  );
}

export default Services;
