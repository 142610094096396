import React from "react";
import {
  Box,
  Flex,
  Text,
  Link,
  Divider,
  Heading,
  Image,
  HStack,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { Link as ScrollLink } from "react-scroll";
import BrandLogo from '../assests/navbarImg/Group 31.png';
import imagefta from "../assests/FooterImg/imagefta.png";
import imageftb from "../assests/FooterImg/imageftb.png";
import imageftc from "../assests/FooterImg/imageftc.png";
import imageftd from "../assests/FooterImg/imageftd.png";
import { Link as RouterLink } from "react-router-dom";

// import { Stack } from "react-bootstrap";

const Footer = () => {
  return (
    <Box
      p={{ base: 5, md: 8,lg:10 }}
    style={{  transition: "all 0.9s",
    background: "linear-gradient(to right, #bdc3c7, #2c3e50)",}}
      maxW="full"
      marginInline="auto"
    >
      <Stack
        spacing={{ base: 8, md: 0 }}
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
      >
        <HStack
          spacing={4}
          d={{ base: "none", sm: "flex" }}
          justifyContent={{ sm: "space-between", md: "normal" }}
        >
          <VStack
            style={{ fontFamily: "Nunito Sans" }}
            spacing={4}
            ml="10px"
            mb="40"
           
            // border="1px solid red"
            alignItems="flex-start"
          >
            <Text fontSize="lg" color="#ffffff" fontWeight="bold">
              Quick Links
            </Text>
            <Box display="flex" gap={20}>
            <VStack
              style={{ fontFamily: "Nunito Sans" }}
              spacing={2}
              fontSize="lg"
              cursor="pointer"
              _hover="blue"
              alignItems="flex-start"
              color="#ffffff"
            >
              <CustomLink to="Home-section">Home</CustomLink>
            <RouterLink to="/portfolio">Portfolio</RouterLink>
              <CustomLink to="subject-section">Subjects</CustomLink>
              <CustomLink to="pricing-section">Pricing</CustomLink>
              <RouterLink to="/pay">Pay</RouterLink>
             
              {/* <a href="https://apps.apple.com/app/philafun/id6448131825"><Image mt={8} width="65%" src={""} /></a> 
              <a href="https://play.google.com/store/apps/details?id=com.philafun.philafun"><Image width="65%" src={""} /></a>  */}
            </VStack>
            {/* <VStack
              style={{ fontFamily: "Nunito Sans" }}
              spacing={2}
              cursor="pointer"
            
              fontSize="lg"
              _hover="blue"
              alignItems="flex-start"
              color="#ffffff"
            >
              <CustomLink to="Home"> FAQs</CustomLink>
              <CustomLink to="AboutSection">Privacy Policy</CustomLink>
              <CustomLink to="WorkSection">Terms of Use</CustomLink>
              <CustomLink to="Download">Help and Support</CustomLink>
            
            
            </VStack> */}
            </Box>
           
          </VStack>
        </HStack>
       <Box>
       <Box >
        <Image mt={{lg:"0%",base:"20%"}} h={{base:"",lg:"16vh"}}  w={{lg:"110px",base:"50%"}} margin={{base:"auto"}} ml={{lg:"520px"}} src={BrandLogo} alt="s" />
        </Box>
       </Box>
        <Box maxW="708px">
          
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize="lg"
            fontWeight="bold"
            color="#ffffff"
            textAlign="start"
            // ml={20}
          >
            About Us
          </Text>
<Box display="flex" gap={10}>
{/* <Image mt={{lg:"3%",base:"20%"}} h={{base:"10vh",lg:"16vh"}}  w={{lg:"100px"}} src={BrandLogo} alt="s" /> */}
<Text
            style={{ fontFamily: "Nunito Sans" }}
            mt={5}
            color="#FFFFFF"
            fontSize="md"
            textAlign="start"
          >
           Our team consists of software experts from the best
           schools <br />(Stanford, UC Berkeley, University of Michigan,
           UCIA, USC and etc.)<br />
            around the nation. We are confident we can answer every single<br /> question and provide the best
            service possible for our clients.
          
          </Text>
</Box>
        

       
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            mt={5}
            color="#FFFFFF"
            fontWeight={700}
            fontSize="md"
            mr={{lg:"320px"}}
          >
            Get in touch with us at
          </Text>

          <Stack flexDirection="row"   >
            {/* <Image src={ab} /> */}
            <Box display="flex" mr={{lg:"70px"}} margin={{base:"0"}} ml={{base:"80px",lg:"0px"}} gap={5}  >
            <a  href="https://www.instagram.com/acadroof_assignment_expert/">
              <Image h="10" src={imagefta} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61550715404904">
              <Image h="10" src={imageftc} />
            </a>
            <a href="https://join.skype.com/invite/So2Ajf2AcZTX">
              <Image h="10" src={imageftd} />
            </a>
            <a href="https://wa.me/+14373299028">
              <Image h="10" src={imageftb} />
            </a>
            </Box>
           
          </Stack>
        </Box>
      </Stack>

      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={3}
        justifyContent="space-between"
      >
        <Text
          style={{ fontFamily: "Nunito Sans" }}
          color="#ffffff"
          fontSize="md"
          mt={{base:"10px" ,lg:"0px"}}
        >
          {" "}
          All rights reserved @Acadroof
        </Text>
        <Stack spacing={2}  direction={{ base: "column", md: "row" }}>
          <Text style={{ fontFamily: "Nunito Sans" }} mr="24px" color="#ffffff" textAlign="start">
           <RouterLink to="/terms">Terms of Service</RouterLink> | <RouterLink to="/privacy">Privacy Policy</RouterLink> | <a href="mailto:help@acadroof.com">help@acadroof.com</a>
          </Text>
        </Stack>
      </Stack>
    </Box>
    
  );
};
const CustomLink = ({ children, to, ...props }) => {
  return (
    <ScrollLink
      to={to}
      smooth={true}
      duration={500}
      offset={-50}
      spy={true}
      exact="true"
      activeClass="active"
      {...props}
    >
      {children}
    </ScrollLink>
  );
};


export default Footer;

