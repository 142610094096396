import React from "react";
import { Heading, Text, Flex, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

function TermsOfService() {
  return (
    <motion.div
        
    initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: "auto" }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ duration: 0.9 }}
  >
    <Box
      background="linear-gradient(to right, #bdc3c7, #2c3e50)"
      fontFamily="Nunito Sans"
      p={10}
      // h="1200px"
    >
      <Flex direction="column" color="white" >
        <Heading fontWeight={400} textAlign="center">
          Terms of Service
        </Heading>

        <Text fontSize="lg" mt={4} textAlign="start" >
          <strong>1. Booking Services</strong>
          <br />
          By booking our services, you agree to the following terms and conditions:
          <br />
          - Academic Integrity: Our services are designed to assist you with your educational needs while upholding academic integrity. You are responsible for adhering to the academic policies and guidelines of your institution.
          <br />- Payment: Payment is required in advance to confirm your booking. We accept various payment methods, and details will be provided during the booking process.
          <br />- Refund Policy: Refunds are available as per our refund policy, which outlines the conditions and procedures for requesting refunds. You may request a copy of our refund policy at any time.
          <br />- Confidentiality: We maintain strict confidentiality of all client information and work. Your personal information and the content of your assignments are kept confidential and are not disclosed to third parties.
          <br />- Copyright: The completed work we provide is intended for reference purposes and should not be submitted as your own work. We do not endorse or support plagiarism or academic dishonesty.
        </Text>

        <Text fontSize="lg" mt={4} textAlign="start">
          <strong>2. Changes to Terms and Privacy Policy</strong>
          <br />
          We reserve the right to modify our Terms of Service and Privacy Policy at any time. Any changes will be effective upon posting on our website. It is your responsibility to review these documents periodically for updates.
        </Text>
        <Text fontSize="lg" mt={4} textAlign="start">
  <strong>3. User Responsibilities</strong>
  <br />
  As a user of our services, you have certain responsibilities:
  <br />
  - Account Security: You are responsible for maintaining the security of your account credentials and login information.
  <br />- Compliance with Laws: You must use our services in compliance with all applicable laws and regulations.
  <br />- Accurate Information: Ensure that all information provided to us, including personal details and assignment requirements, is accurate and complete.
</Text>
<Text fontSize="lg" mt={4} textAlign="start">
  <strong>4. Termination of Services</strong>
  <br />
  We reserve the right to terminate or suspend our services to any user at our discretion, with or without cause. If your services are terminated, you will be notified accordingly.
</Text>
<Text fontSize="lg" mt={4} textAlign="start">
  <strong>5. User Conduct</strong>
  <br />
  When using our services, you agree to:
  <br />
  - Respect other users and our staff.
  <br />- Not engage in any harmful or disruptive activities, including but not limited to hacking, spamming, or distributing malware.
  <br />- Not impersonate any person or entity or misrepresent your identity.
</Text>

<Text fontSize="lg" mt={4} textAlign="start">
  <strong>6. Intellectual Property</strong>
  <br />
  All content and materials provided by our services, including but not limited to text, graphics, logos, and software, are protected by intellectual property laws. You may not use, reproduce, or distribute these materials without our explicit permission.
</Text>

<Text fontSize="lg" mt={4} textAlign="start">
  <strong>7. Limitation of Liability</strong>
  <br />
  We are not liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages, arising from the use of our services. Our liability is limited to the extent permitted by applicable law.
</Text>

<Text fontSize="lg" mt={4} textAlign="start">
  <strong>8. Dispute Resolution</strong>
  <br />
  Any disputes arising from your use of our services will be resolved through arbitration in accordance with [Arbitration Rules]. You agree to waive your right to pursue claims through class actions or as part of a collective action.
</Text>

<Text fontSize="lg" mt={4} textAlign="start">
  <strong>9. Governing Law</strong>
  <br />
  This agreement is governed by and construed in accordance with the laws of [Jurisdiction]. Any legal actions or proceedings related to this agreement will be filed in the courts of [Jurisdiction].
</Text>

<Text fontSize="lg" mt={4} textAlign="start">
  <strong>10. Contact Information</strong>
  <br />
  If you have any questions, concerns, or requests related to these Terms of Service, please contact us at [Your Contact Information].
</Text>


      </Flex>
    </Box>
    </motion.div>
  );
}

export default TermsOfService;
