import React from 'react';
import Services from './Services';
import serviceData from './serviceData'; // Import the serviceData array

function Variables() {
  const commonHeading = "Our Services and Features"; // Common heading
  const commonParagraph = (
    <>
      Discover our comprehensive services and unique features tailored
      <br />
      to meet your needs and exceed your expectations.
    </>
  ); // Common paragraph

  return (
    <div>
      <Services
        commonHeading={commonHeading}
        commonParagraph={commonParagraph}
        servicesData={serviceData} // Pass the serviceData array
      />
    </div>
  );
}

export default Variables;
