import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../components/navbar/Navbar';
import Slider from '../components/Homepage/Slider';
import Variables from '../components/Service/Variables';
import Main from '../components/subjects/SubVar';
import Sponcers from '../components/sponcers/Sponcers';
import Testimonials from '../components/testinomials/Testi';
import PricingPage from '../components/pricing/Price';
import Footer from '../components/footer/Footer';
import LoadingScreen from '../components/splash-screen/LoadingScreen';

import '../css/My.css'
import '../App.css';

function Home_Route() {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate further loading (e.g., data fetching) if needed
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Adjust the duration as needed
  }, []);
  return (
    <div>
    {isLoading ? (
      <LoadingScreen />
    ) : (
      <ChakraProvider>
        <Navbar />
        <Slider />
        <Variables />
        <Main />
        <Sponcers />
        <Testimonials />
        <PricingPage />
        <Footer />
        <ToastContainer />
      </ChakraProvider>
    )} 
  </div>
  );
}

export default Home_Route;
