import React, { useState } from 'react';
import { IconButton ,Image} from '@chakra-ui/react';
import { FaComment } from 'react-icons/fa';
import ChatComponent from './ChatComponent';

const chatButtonStyle = {
  position: 'fixed',
  bottom: 60,
  right: 10,
  margin: '16px',
  zIndex: 1000, /* Ensure it's above other content */
  transition: 'transform 0.3s ease-in-out',
};

const ChatButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div style={{ ...chatButtonStyle, transform: isChatOpen ? 'translateY(0)' : 'translateY(100%)' }}>
      <Image
        // icon={<FaComment />}
        width="70%"
       src=''
        className="chat-icon-button"
        onClick={toggleChat}
        isRound
      />
      {isChatOpen && <ChatComponent />}
    </div>
  );
};

export default ChatButton;
