import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  IconButton,
  Avatar,
  Heading,
  Image,
} from "@chakra-ui/react";

import rX from "../assests/TestiIMG/rX.png";

import { MdArrowForwardIos,MdArrowBackIos } from "react-icons/md";

function Testimonials() {
  const testimonials = [
    {
      name: "Olivia Doe",
      text: "I m so grateful for the support. Their patience and expertise helped me to excel in my computer science course!",
      star: "⭐️⭐️⭐️⭐️⭐️",
      university: "Lamar University",
      avatarUrl: "https://images.pexels.com/photos/3885528/pexels-photo-3885528.jpeg?auto=compress&cs=tinysrgb&w=800",
    },
    {
      name: "Jane Smith",
      text: "I was struggling with coding assignments, but with the help of , I have gained confidence and improved my skills significantly",
      star: "⭐️⭐️⭐️⭐️⭐️",
      university: "University of Pennsylvania",
      avatarUrl: "https://images.pexels.com/photos/6914039/pexels-photo-6914039.jpeg?auto=compress&cs=tinysrgb&w=800",
    },
    {
      name: "Alice Johnson",
      text: "The online tutoring service is fantastic. The flexibility to schedule sessions around my busy schedule has been a game-changer.",
      star: "⭐️⭐️⭐️⭐️⭐️",
      university: "University of California",
      avatarUrl: "https://images.pexels.com/photos/4064817/pexels-photo-4064817.jpeg?auto=compress&cs=tinysrgb&w=800",
      
    },
    {
      name: "James Miller",
      text: "I m so grateful for the support. Their patience and expertise helped me to excel in my computer science course!",

      star: "⭐️⭐️⭐️⭐️⭐️",
      university: "University of Oxford",
      avatarUrl:
        "https://images.pexels.com/photos/7989098/pexels-photo-7989098.jpeg?auto=compress&cs=tinysrgb&w=800",
    },
    {
      name: "Richard Martin",
      text: "The online tutoring service is fantastic. The flexibility to schedule sessions around my busy schedule has been a game-changer.",
      star: "⭐️⭐️⭐️⭐️⭐️",
      university: "Harvard University",
      avatarUrl:
        "https://img.freepik.com/premium-photo/single-portrait-smiling-confident-male-student-teenager-looking-camera-library_116407-15512.jpg",
    },
    {
      name: "Robert Anderson",
      text: "I was struggling with coding assignments, but with the help of , I have gained confidence and improved my skills significantly",
      star: "⭐️⭐️⭐️⭐️⭐️",
      university: "University of Cambridge",
      avatarUrl:
        "https://media.istockphoto.com/id/1158958655/photo/university-student-stock-images.jpg?s=612x612&w=0&k=20&c=ORWc0SEjkOGvKN1hnPH8-3R9lESVcbi6w0gxa7GQzY4=",
    },
  ];

  const testimonialsPerPage = 3;
  const totalTestimonials = testimonials.length;
  const totalPages = Math.ceil(totalTestimonials / testimonialsPerPage);

  const [currentPage, setCurrentPage] = useState(0);

  const previousPage = () => {
    setCurrentPage((currentPage - 1 + totalPages) % totalPages);
  };

  const nextPage = () => {
    setCurrentPage((currentPage + 1) % totalPages);
  };

  const startIndex = currentPage * testimonialsPerPage;
  const endIndex = startIndex + testimonialsPerPage;

  return (
    <Box  pb={20}>
      <Box textAlign="center" marginTop="40px" >
        <Heading fontWeight="500"  style={{ fontFamily: "Nunito Sans" }}>Testimonials</Heading>
        <Text fontWeight="500" color="grey"  style={{ fontFamily: "Nunito Sans" }}>
          Read our client testimonials and discover how our services have helped{" "}
          <br />
          them achieve their goals and exceed expectations.
        </Text>
      </Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginTop="20px"
        flexDirection={{ base: "column", md: "row" }}
        overflowX="auto"
      >
        <IconButton
          icon={<MdArrowBackIos />}
          onClick={previousPage}
          bg="none"
          size="lg"
          _hover="none"
        />
        <Flex
          alignItems="center"
          marginBottom={{ base: "20px", md: "0" }}
          marginLeft={-20}
          marginRight={-20}
          height="67vh"
          overflow="hidden"
        >
          {testimonials
            .slice(startIndex, endIndex)
            .map((testimonial, index) => (
              <Box
                key={index}
                width="300px"
                padding="20px"
                height="400px"
                backgroundColor="#F0F0F0"
                margin="75px"
                borderRadius="10px"
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
                position="relative"
              >
                <Image
                  width="14%"
                  position="absolute"
                  left="4"
                  top="5"
                  src={rX}
                />
                <Text  style={{ fontFamily: "Nunito Sans" }} marginTop="60px">{testimonial.text}</Text>
                <Text>{testimonial.star}</Text>
                <Text  style={{ fontFamily: "Nunito Sans" }} fontWeight="bold" >
                  {testimonial.name}
                </Text>
                <Text  style={{ fontFamily: "Nunito Sans" }} fontWeight="700" color="grey" >
                  {testimonial.university}
                </Text>
                <Avatar
               boxSize="140px"
                  name={testimonial.name}
                  // boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" 
                  src={testimonial.avatarUrl}
                  position="absolute"
                  bottom="-50px"
                  zIndex={10} 
                />
              </Box>
            ))}
        </Flex>
        <IconButton
          icon={<MdArrowForwardIos />}
          onClick={nextPage}
          bg="none"
          size="lg"
          _hover="none"
          boxSize={20}
        />
      </Flex>
    </Box>
  );
}

export default Testimonials;
