import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Input,
  Select,
  Box,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Image,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import ConfirmationModal from "./ConfirmationModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ThirdModal({ isOpen, onClose, planName, price }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formData, setFormData] = useState({
    
    name: "",
    toEmail: "coderoofitsolutions@gmail.com",
    senderEmail: "", 
    subject: "",
    csubject: "", 
    topic: "",
    selectedDate: "",
    timezone: "",
    selectedPreferredTime: "",
    selectedDeadlineDate: "",
    selectedDeadlineTime: "",
    selectedPlan: planName,
    selectedPrice: price,
    message: "",
    selectedFile: null,
  });

  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);


  useEffect(() => {
    if (isOpen) {
      axios
        .get("https://acadroof-backend.onrender.com/subjects")
        .then((response) => {
          setSubjects(response.data);
        })
        .catch((error) => {
          console.error("Error fetching subjects:", error);
        });
    }
  }, [isOpen]);

  // Use useEffect to update csubject whenever subject changes
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      csubject: prevData.subject,
    }));
  }, [formData.subject]);

  const isFormValid = () => {
    const {
      name,
      senderEmail,
      subject,
      topic,
      selectedDate,
      selectedPreferredTime,
      selectedDeadlineDate,
      selectedDeadlineTime,
      message,
    } = formData;

    // Check if any of the required fields is empty
    if (
      !name ||
      !senderEmail ||
      !subject ||
      !topic ||
      !selectedDate ||
      !selectedPreferredTime ||
      !selectedDeadlineDate ||
      !selectedDeadlineTime ||
      !message
    ) {
      return false; // Form is not valid
    }

    return true; // Form is valid
  };

  // Update formValid whenever formData changes
  useEffect(() => {
    setFormValid(isFormValid());
  }, [formData]);

  const handleBookNow = (formData) => {
    // You can use formData to send the collected data to your booking logic or API
    // For now, we'll just close the confirmation modal
    setShowConfirmationModal(false);
  };

  const fetchTopics = (selectedSubject) => {
    const selectedSubjectData = subjects.find(
      (subject) => subject.name === selectedSubject
    );
    if (selectedSubjectData) {
      setTopics(selectedSubjectData.descriptions);
    } else {
      setTopics([]);
    }
  };

  const handleSubmit = async () => {
    try {
      console.log("Form Data:", formData); 
      const formDataToSend = new FormData(); 
      formDataToSend.append("name", formData.name);
      formDataToSend.append("senderEmail", formData.senderEmail);
      formDataToSend.append("subject", formData.subject);
      formDataToSend.append("csubject", formData.csubject);
      formDataToSend.append("topic", formData.topic);
      formDataToSend.append("selectedDate", formData.selectedDate);
      formDataToSend.append( "selectedDeadlineDate",formData.selectedDeadlineDate);
      formDataToSend.append("selectedDeadlineTime",formData.selectedDeadlineTime);
      formDataToSend.append("timezone", formData.timezone);
      formDataToSend.append("file", formData.selectedFile);
      formDataToSend.append("message", formData.message);
      formDataToSend.append("selectedPreferredTime",formData.selectedPreferredTime);
      formDataToSend.append("selectedPrice", formData.selectedPrice);
      formDataToSend.append("selectedPlan", formData.selectedPlan);

      const response = await axios.post(
        "https://acadroof-backend.onrender.com/sendEmail",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type for FormData
          },
        }
      );
      console.log(response.data.message);
      toast.success("Email sent successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // onClose();
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleRemoveFile = () => {
    setFormData({ ...formData, selectedFile: null });
  };
const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Changing ${name} to ${value}`);
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "subject") {
      fetchTopics(value);
    }
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024); // Calculate file size in MB
      if (fileSizeInMB <= 20) {
        // If file size is within the limit, set it in formData
        setFormData({
          ...formData,
          selectedFile: selectedFile,
        });
      } else {
        // If file size exceeds the limit, show a Toastify alert
        toast.error("File size exceeds 20MB limit.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
 return (
  <>
    <Modal isOpen={isOpen} onClose={onClose}>
      <ToastContainer />
 <ModalOverlay />
      <ModalContent
        maxW={{ base: "100%", md: "800px", lg: "500px" }}
        padding="10px"
        bg="#F0F0F0"
      >
          <ModalHeader fontWeight="700" color="blue.500">Selected Option</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel color="" fontWeight="bold">Name</FormLabel>
              <Input
                type="text"
                name="name"

                bgColor="gray.300"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
              />
            </FormControl>

            <FormControl>
              <FormLabel color="" fontWeight="bold">Email</FormLabel>
              <Input
                type="email"
                name="senderEmail"
                bgColor="gray.300"
                value={formData.senderEmail}
                onChange={handleChange}
                placeholder="Enter your email"
              />
            </FormControl>
            <Box display="flex" gap="92px">
              <FormControl >
                <FormLabel color="" fontWeight="bold">Subject</FormLabel>
                <Select
                  name="subject"
                  bgColor="gray.300"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Select a subject"
                >
                  {/* <option value="">Select a subject</option> */}
                  {subjects.map((subject) => (
                    <option key={subject._id} value={subject.name}>
                      {subject.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel color="" fontWeight="bold">Topic</FormLabel>
                <Select
                  name="topic"
                  bgColor="gray.300"
                  value={formData.topic}
                  onChange={handleChange}
                  placeholder="Select a topic"
                >
                  {/* <option value="">Select a topic</option> */}
                  {topics.map((topic) => (
                    <option key={topic} value={topic}>
                      {topic}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <FormControl>
  <FormLabel color="" fontWeight="bold">Timezone</FormLabel>
  <Select
    name="timezone"
    bgColor="gray.300"
    value={formData.timezone}
    onChange={handleChange}
    placeholder="Select a timezone"
  >
    {/* <option value="">Select a timezone</option> */}
    <option value="Eastern Standard Time">(GMT-05:00) Eastern Standard Time</option>
    <option value="Central Standard Time">(GMT-06:00) Central Standard Time</option>
    <option value="Pacific Standard Time">(GMT-08:00) Pacific Standard Time</option>
    <option value="Mountain Standard Time">(GMT-07:00) Mountain Standard Time</option>
  </Select>
</FormControl>
         <FormControl >
              <FormLabel color="" fontWeight="bold">Start Date & Time</FormLabel>
              <Flex gap={10}>
                <Input
                  type="date"
                  // width="100px"
                  bgColor="gray.300"
                  name="selectedDate"
                  value={formData.selectedDate}
                  onChange={handleChange}
                />
                <Input
                  type="time"
                  bgColor="gray.300"
                  // width="w00px"
                  name="selectedPreferredTime"
                  value={formData.selectedPreferredTime}
                  onChange={handleChange}
                />
              </Flex>
            </FormControl>
            <FormControl>
              <FormLabel color="" fontWeight="bold">Deadline Date & Time</FormLabel>
              <Flex gap={10}>
                <Input
                  type="date"
                  bgColor="gray.300"
                  name="selectedDeadlineDate"
                  value={formData.selectedDeadlineDate}
                  onChange={handleChange}
                />
                <Input
                  type="time"
                  name="selectedDeadlineTime"
                  bgColor="gray.300"
                  value={formData.selectedDeadlineTime}
                  onChange={handleChange}
                />
              </Flex>
            </FormControl>
            {/* Display uploaded picture and remove button */}
            {formData.selectedFile && (
              <FormControl>
                <FormLabel color="" fontWeight="bold">Uploaded Picture</FormLabel>
                <Flex align="center">
                  <Box>
                    <Image
                      src={URL.createObjectURL(formData.selectedFile)}
                      alt="Uploaded Picture"
                      boxSize="100px"
                      objectFit="cover"
                    />
                  </Box>
                  <Button
                    variant="outline"
                    colorScheme="red"
                    size="sm"
                    ml="2"
                    onClick={handleRemoveFile}
                  >
                    Remove
                  </Button>
                </Flex>
              </FormControl>
            )}
            {!formData.selectedFile && (
              <FormControl>
                <FormLabel color="" fontWeight="bold">Upload File</FormLabel>
                <Input
                  type="file"
                  name="file"
                  bgColor="gray.300"
                  accept="*"
                  onChange={handleFileChange}
                />
              </FormControl>
            )}
            <FormControl>
              <FormLabel color="" fontWeight="bold">Selected Plan</FormLabel>
              <HStack>
                <Text fontWeight="700" color="green">{planName}</Text>
                <Text ml="2" color="" fontWeight="bold">
  Price:
  <strong style={{ color: "green" }}>$</strong>
  <strong style={{ color: "green" }}>{price}</strong>
</Text>


              </HStack>
            </FormControl>
            <FormControl>
  <FormLabel color="" fontWeight="bold">Message</FormLabel>
  <Textarea
    name="message"
    bgColor="gray.300"
    value={formData.message}
    onChange={handleChange}
    placeholder="Enter your message"
  />
</FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
        <Button
          onClick={() => {
            if (formValid) {
              handleSubmit();
              setShowConfirmationModal(true);
            }
          }}
          colorScheme="blue"
          width="full"
          isDisabled={!formValid} // Disable the button if the form is not valid
        >
          Book my Session
        </Button>
          {/* <Button variant="outline" onClick={onClose}>
            Close
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
     {showConfirmationModal && (
      <ConfirmationModal
        isOpen={showConfirmationModal}
        
        onClose={() => setShowConfirmationModal(false)}
        formData={formData}
        selectedPrice={price}
        onBookNow={handleBookNow}
      />
    )}
  </>
  );
}
export default ThirdModal;
