import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  ChakraProvider,
  Box,
  Heading,
  Text,
  Input,
  Grid,
  Flex,
  Image,
  Button,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { RiSearchLine } from "react-icons/ri";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Footer from "../footer/Footer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const PortfolioItem = ({ Topic, imageSrc, text ,subject}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    if (showDetails) {
      document.body.classList.add("details-open");
    } else {
      document.body.classList.remove("details-open");
    }
  }, [showDetails]);

  const openModal = () => {
    setShowDetails(true);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setShowDetails(false);
    setModalIsOpen(false);
  };

  return (
    <Box p={4} w="100%" >
    <Box  boxShadow="2xl" p={10} height={{lg:"80vh"}}>
      <Heading fontWeight="450" fontSize={{ base: "20px", md: "26px" }}>
        {Topic}
      </Heading>
      <Heading fontWeight="450" fontSize={{ base: "20px", md: "26px" }}>
        {subject}
      </Heading>
      <Image  src={imageSrc[0]} alt={Topic} height={{lg:"50vh",base:"20vh"}} width={{lg:"700px",base:""}} />
      <Text>{showDetails ? text : text.slice(0, 177)}</Text>
      <Flex justifyContent="start">
        <Button colorScheme="blue" onClick={openModal}>
          View Details
        </Button>
      </Flex>
    </Box>
    {/* Modal for displaying carousel */}
    <Modal isOpen={modalIsOpen} onClose={closeModal} size="3xl" >
      <ModalOverlay />
      <ModalContent >
        <ModalHeader>{Topic} Images</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Carousel autoPlay>
            {imageSrc.map((imageUrl, index) => (
              <div key={index}>
                <img src={imageUrl} alt={`${Topic} - Image ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </ModalBody>
        <ModalFooter>
          {/* <Button colorScheme="blue" onClick={closeModal}>
            Close
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  </Box>
);

  
};

const Portfolio = () => {
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [portfolioData, setPortfolioData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get("https://acadroof-backend.onrender.com/portfolio")
      .then((response) => {
        setPortfolioData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  }, []);
  

  // Updated filtering logic
  const filteredData = portfolioData.filter((item) =>
    (selectedSubject === "" || item.subject === selectedSubject) &&
    (item.Topic.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.text.toLowerCase().includes(searchQuery.toLowerCase()))
  );


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSubjectFilter = (subject) => {
    setSelectedSubject(subject);
    setCurrentPage(1); // Reset the page when a subject is selected
  };


  return (
    <ChakraProvider >
      <Box textAlign="center" mt="30px"  >
        <Heading fontFamily="Nunito Sans" color="blue.400" fontWeight="700" letterSpacing={4} fontSize={{ base: "24px", md: "36px" }}>
          Our Portfolio
        </Heading>
        <Flex alignItems="center" justifyContent="start" ml={10}>
          <Link to="/">
            <Button fontFamily="Nunito Sans" colorScheme="blackAlpha" rounded="full">
              Back
            </Button>
          </Link>
        </Flex>
      </Box>
      <Box textAlign="center" my={4}>
        <Text fontFamily="Nunito Sans">
          Explore our portfolio showcasing our diverse range of successful projects,<br />
          demonstrating our expertise and creativity.
        </Text>
      </Box>
      <Flex justifyContent="center" my={4} mt={0}>
        <InputGroup w={{ base: "90%", md: "540px" }}>
          <Input
            type="text"
            fontFamily="Nunito Sans"
            placeholder="Search for topics"
            fontSize={{ base: "16px", md: "18px" }}
            borderRadius="10px"
            padding="5px"
            color="#000000"
            bg="#D9D9D9"
            pl="15px"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <InputRightElement top="50%" transform="translateY(-50%)">
            <RiSearchLine fontSize="20px" />
          </InputRightElement>
        </InputGroup>
      </Flex>
      <Flex justifyContent="center" mt={4} mb={4} flexWrap="wrap">
  {/* Subject buttons */}
  <Button
   mx={2}
   my={2} // Add vertical margin for spacing
   variant={selectedSubject === "" ? "solid" : "outline"}
   boxShadow="md" // Adjust the shadow
   border="2px solid black"
   colorScheme="green"
   onClick={() => handleSubjectFilter("")}
   w={{ base: "100%", sm: "auto" }} // Make the button full width on mobile and auto-width on larger screens
   borderRadius="30px"
  >
    All
  </Button>
  <Button
    mx={2}
    my={2}
    variant={selectedSubject === "Physics" ? "solid" : "outline"}
    onClick={() => handleSubjectFilter("Physics")}
    boxShadow="md"
    border="2px solid black"
    w={{ base: "100%", sm: "auto" }}
    borderRadius="30px"
    >
    Physics
  </Button>
 
  <Button
    mx={2}
    my={2}
    variant={selectedSubject === "Chemistry" ? "solid" : "outline"}
    onClick={() => handleSubjectFilter("Chemistry")}
    boxShadow="md"
    border="2px solid black"
    w={{ base: "100%", sm: "auto" }}
    borderRadius="30px"
  >
    Chemistry
  </Button>
  <Button
    mx={2}
    my={2}
    variant={selectedSubject === "Biology" ? "solid" : "outline"}
    onClick={() => handleSubjectFilter("Biology")}
    boxShadow="md"
    border="2px solid black"
    w={{ base: "100%", sm: "auto" }}
    borderRadius="30px"
  >
    Biology
  </Button>
  <Button
    mx={2}
    my={2}
    variant={selectedSubject === "Maths" ? "solid" : "outline"}
    onClick={() => handleSubjectFilter("Maths")}
    boxShadow="md"
    border="2px solid black"
    w={{ base: "100%", sm: "auto" }}
    borderRadius="30px"
  >
    Maths
  </Button>
  <Button
    mx={2}
    my={2}
    variant={selectedSubject === "Computer Science" ? "solid" : "outline"}
    onClick={() => handleSubjectFilter("Computer Science")}
    boxShadow="md"
    border="2px solid black"
    w={{ base: "100%", sm: "auto" }}
    borderRadius="30px"
  >
    Computer Science
  </Button>
  {/* Add more subject buttons as needed */}
</Flex>

      
      <motion.div
        key={currentPage}
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1, transition: { duration: 0.9, ease: "easeInOut" } }}
        exit={{ x: -100, opacity: 0, transition: { duration: 0.9, ease: "easeInOut" } }}
      >
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
          gap={6}
          padding={{ base: "20px", md: "40px" }}
        >
          {filteredData.slice(startIndex, endIndex).map((item, index) => (
            <PortfolioItem
              key={index}
              Topic={item.Topic}
              // subject={item.subject}
              imageSrc={item.imageSrc}
              text={item.text}
            />
          ))}
        </Grid>
    
<Flex justifyContent="center" mt={4} mb={8}>
  {Array.from({ length: Math.ceil(portfolioData.length / itemsPerPage) }).map((_, index) => (
    <Button
      key={index}
      mx={1}
      variant={currentPage === index + 1 ? "solid" : "outline"}
      colorScheme="blue"
      onClick={() => handlePageChange(index + 1)}
    >
      {index + 1}
    </Button>
  ))}
</Flex>

      </motion.div>
      <Footer />
    </ChakraProvider>
  );
};

export default Portfolio;
