import React, { useState, useEffect } from 'react';
import { Box, Heading, Input, InputRightElement, InputGroup, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Button, Flex,Spinner} from '@chakra-ui/react';
import { RiSearchLine } from 'react-icons/ri';
import SecondModal from "./SecondModal";
import axios from 'axios';
import ThirdModal from './ThModal'
import { Link } from "react-router-dom";
import { motion } from "framer-motion";


const SubVar = () => {
  const [searchText, setSearchText] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [selectedTopicData, setSelectedTopicData] = useState(null); // Store data for the selected topic
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showThirdModal, setShowThirdModal] = useState(false);
  const [loading, setLoading] = useState(true);
  
  

  useEffect(() => {
    // Fetch subjects from the API
    fetch('https://acadroof-backend.onrender.com/subjects')
      .then((response) => response.json())
      .then((data) => {
        setSubjects(data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching subjects:', error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, []);

  useEffect(() => {
    const filtered = subjects.filter((subject) => {
      const subjectNameMatch = subject.name?.toLowerCase().includes(searchText.toLowerCase());
      const matchingTopics = subject.descriptions.filter((description) =>
        description.toLowerCase().includes(searchText.toLowerCase())
      );
      return subjectNameMatch || matchingTopics.length > 0;
    });

    setFilteredSubjects(filtered);
  }, [searchText, subjects]);

  const [apiData, setApiData] = useState(null);
  useEffect(() => {
    axios
      .get("https://acadroof-backend.onrender.com/getPrices")
      .then((response) => {
        setApiData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  }, []);
  // Function to open the second modal and store selected topic data
  const handleBookNowClick = (topicData) => {
    setSelectedTopicData(topicData);
    setShowSecondModal(true); // Set the flag to open the second modal
  };
  
  

  return (
    <motion.Box
  p={4}
  bg="gray.100"
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 0.5 }} // Adjust the animation duration as needed
>
    <Box p={4} bg="gray.100"     id="subject-section">
      {/* Heading */}
      <Text fontSize="4xl" letterSpacing={5}  mb={4} style={{ fontFamily: 'Nunito Sans',fontWeight:'500' }}>
        Subject
      </Text>
      <Text fontWeight="bold" color="gray.500" style={{ fontFamily: 'Nunito Sans' }}>
        Explore our portfolio showcasing our diverse range of successful
        projects,<br /> demonstrating our expertise and creativity
      </Text>
      {/* Search Bar */}
      <InputGroup w={{ base: '90%', md: '540px' }} h="52px" margin="auto"  style={{ fontFamily: "Nunito Sans" }}>
        <Input
          type="text"
          placeholder="Search for topics e.g. Thermodynamics"
          fontSize={{ base: '16px', md: '18px', lg: '20px' }}
          borderRadius="10px"
          fontWeight="400"
          padding="5px"
          color="#000000"
          backgroundColor="#D9D9D9"
          pl="15px"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <InputRightElement top="38%" transform="translateY(-50%)">
          <RiSearchLine fontSize="20px" />
        </InputRightElement>
      </InputGroup>

       {/* Show loader while loading */}
       {loading && (
          <Flex justify="center" align="center" height="100px">
            <Spinner size="lg" color="blue.500" />
          </Flex>
        )}

      {/* Accordion for Subjects */}
      {!loading && (
          <Accordion mt={4} allowToggle maxW="500px" margin="auto" style={{ fontFamily: "Nunito Sans" }}>
            {filteredSubjects.map((subject) => (
              <motion.div
                key={subject._id}
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.9 }}
              >
                <AccordionItem key={subject._id}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" style={{ fontFamily: "Nunito Sans" }}>
                        {subject.name}
                      </Box>
                      <Box mt={{ base: "10px", md: "0" }}>
                        <Link to="/portfolio">
                          <Button
                            color="#2F9AE6"
                            bg="none"
                            fontSize="16px"
                            fontWeight="500"
                            _hover="none"
                            style={{ fontFamily: "Nunito Sans" }}
                            ml="5px"
                            textDecoration="underline"
                          >
                            View Portfolio
                          </Button>
                        </Link>
                      </Box>
                      <Box
                        backgroundColor="#2196F3"
                        borderRadius="20%"
                        width="21px"
                        height="21px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                      >
                        <AccordionIcon />
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <ul>
                      {subject.descriptions.map((description) => (
                        <li key={description} className="list-item-transition">
                          <Flex justify="space-between" align="center">
                            <Text style={{ fontFamily: "Nunito Sans" }}>{description}</Text>
                            <Button
                              size="xs"
                              border="1px solid #2F9AE6"
                              color="#2F9AE6"
                              style={{ fontFamily: "Nunito Sans" }}
                              bg="none"
                              onClick={() =>
                                handleBookNowClick({
                                  name: subject.name,
                                  topic: description,
                                })
                              }>
                              Book Now
                            </Button>
                          </Flex>
                        </li>
                      ))}
                    </ul>
                  </AccordionPanel>
                </AccordionItem>
              </motion.div>
            ))}
          </Accordion>
        )}

        {/* Second Modal */}
        {showSecondModal && (
          <SecondModal
            isOpen={showSecondModal}
            onClose={() => setShowSecondModal(false)}
            apiData={apiData}
            subject={selectedTopicData.name}
            topic={selectedTopicData.topic}
          />
        )}
        {/* Third Modal */}
        {showThirdModal && (
          <ThirdModal
            isOpen={showThirdModal}
            onClose={() => setShowThirdModal(false)}
            subject={selectedTopicData.name}
            topic={selectedTopicData.topic}
          />
        )}
      </Box>
    </motion.Box>
  );
};

export default SubVar;
