import React from 'react';
import { Box, Heading, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import acaImage from '../assests/SponcersIMG/aca.png';
import acbImage from '../assests/SponcersIMG/acb.png';
import accImage from '../assests/SponcersIMG/acc.png';
import acdImage from '../assests/SponcersIMG/acd.png';
import aceImage from '../assests/SponcersIMG/ace.png';

function Sponsors() {
  const sponsorImages = [
    acaImage,
    acbImage,
    accImage,
    acdImage,
    aceImage
  ];

  const imageSize = useBreakpointValue({ base: '10%', md: '7%', lg: '7%' });

  return (
    <Box
      // mt={10}
      backgroundColor="#2196F3"
      
      padding="20px"
      height={{lg:'50%'}}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box textAlign="center" marginBottom="20px" mt={10}>
        <Heading   fontWeight="500" color="white">Our Sponsors</Heading>
        <Text color="white"   style={{ fontFamily: "Nunito Sans" }}>
          Our sponsors are valuable partners, supporting our mission and enabling<br/>
          us to provide high-quality services.
        </Text>
       
      </Box>

      <Box
      mt={10}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        {sponsorImages.map((imageSrc, index) => (
          <Box
            key={index}
            width={imageSize}
            height={imageSize}
            margin="10px"
            marginLeft={{ lg: index !== 0 ? '150px' : '0' }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image width="85%" height="100%" src={imageSrc} alt={`Sponsor ${index + 1}`} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Sponsors;
