import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomNavbarToggle from './BurgerMenu';
import './Nav.css'; // Make sure to include your custom CSS file
import infoImage from '../assests/navbarImg/image 128.png';
import lightImage from '../assests/navbarImg/image 129.png';
import BrandLogo from '../assests/navbarImg/Group 31.png';

function NavbarComponent() {
  const [expanded, setExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleScroll = () => {
    const subjectSection = document.getElementById('subject-section');
    subjectSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleScrollHome = () => {
    document.getElementById('Home-section').scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  const handleScrollPrice = () => {
    document.getElementById('pricing-section').scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };


  useEffect(() => {
    console.log("location", window.location)
    console.log("state", document.readyState)
   if(window.location.pathname === '/' && window.location.hash === '#pricing-section') {
    handleScrollPrice()
   }
   if(window.location.pathname === '/' && window.location.hash === '#subject-section') {
    handleScroll()
   }
  }, [])

  const marginRight = windowWidth >= 992 ? '140px' : '0';
  const isMobile = windowWidth < 992;

  return (
    <div>
      <Navbar expand="lg" fixed="top" className="transparent-navbar">
      <Navbar.Brand
  as={Link}
  to="/"
  style={{
    // paddingTop: isMobile ? '10px' : '',
    marginLeft: isMobile ? '370px' : '11px',
    // marginRight: isMobile ? '' : '0', 
    color: 'white',
    fontFamily: 'cursive',
  }}
>
  <img width={isMobile ? '0%' : '9%'}  src={BrandLogo} alt="Brand Logo" />
</Navbar.Brand>



        <Navbar.Toggle onClick={handleToggle} aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={`justify-content-center ${expanded ? 'show' : ''}`}>
        <Nav
      className={`ml-auto ${expanded ? 'flex-column align-items-center' : ''}`}
      style={{ marginRight }}
    >
            <Nav.Link as={Link} to="/" style={{ color: 'white' }} onClick={handleScrollHome}>
              Home
            </Nav.Link>
            <Nav.Link  style={{ color: 'white' }} href="/#subject-section">
              Subject
            </Nav.Link>
            <Nav.Link as={Link} to="/portfolio" style={{ color: 'white' }}>
              Portfolio
            </Nav.Link>
            <Nav.Link style={{ color: 'white' }} href='/#pricing-section'>
              Pricing
            </Nav.Link>
            <Nav.Link as={Link} to="/pay" style={{ color: 'white' }}>
              Pay 
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <div style={{display:"flex", width: isMobile ? '80%' : 'auto',}}>
        <div id='one' style={{
            display: 'flex',
            alignItems: 'center',
            width: isMobile ? '50%' : 'auto', // Adjust the width for mobile screens
          }}>
          <img
              src={infoImage}
              alt="Info"
              style={{
                marginRight: '10px',
                cursor: 'pointer',
                color: 'white',
                width: isMobile ? '10%' : '24%',
              }}
            
          />
          <span style={{ color: 'white' ,fontSize:'14px',fontWeight:"500", width: isMobile ? '90%' : 'auto', }}>+1(437) 329 9028</span>
        </div>
        <div id='two' style={{
            display: 'flex',
            alignItems: 'center',
            width: isMobile ? '50%' : 'auto', // Adjust the width for mobile screens
          }}>
          <img
              src={lightImage}
              alt="Info"
              style={{
                marginRight: '10px',
                cursor: 'pointer',
                color: 'white',
                width: isMobile ? '10%' : '24%',
              }}
            />
          <span style={{ color: 'white',fontSize:'14px',fontWeight:"500" , width: isMobile ? '90%' : 'auto'}}>+1(559) 520 2330</span>
        </div>
        </div>
       

        <CustomNavbarToggle onClick={handleToggle} />
      </Navbar>
      {/* Add the spacing div only on mobile devices */}
      <div className="d-lg-none" style={{ height: '65px' }}></div>
    </div>
  );
}

export default NavbarComponent;
