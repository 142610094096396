import React from 'react';
import { FiMenu } from 'react-icons/fi'; // Import the burger menu icon from the react-icons library

const CustomNavbarToggle = ({ onClick }) => {
  return (
    <div onClick={onClick} className="custom-navbar-toggle">
      {/* <FiMenu size={24} color="white" /> */}
    </div>
  );
};

export default CustomNavbarToggle;
