import React, { useEffect, useState } from 'react';
import './LoadingScreen.css';
// import Logo from '../assests/navbarImg/Group 31.png';
import Los from '../assests/navbarImg/los.png'

function LoadingScreen() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to show the loading screen
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay duration as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`loading-screen ${loading ? 'visible' : 'hidden'}`}>
      {/* Spinning logo */}
      <img src={Los} className="loading-logo" alt="Loading Logo" />
    </div>
    
  );
}

export default LoadingScreen;
