import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Grid,
  GridItem,
  Input,
  Image,
  Button,
  Text,
  Divider,
  ChakraProvider,
  Center,
  Flex,
  Table, Thead, Tbody, Tr, Th, Td
} from "@chakra-ui/react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Swal from "sweetalert2";
import { motion } from "framer-motion";
import '../../App.css';
import '../../index.css'

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useLocation, useSearchParams } from "react-router-dom";
import { Container } from "react-bootstrap";

const PaymentPage = () => {
  const { state } = useLocation();
  const formData = state?.formData; 
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPrice = state?.formData?.selectedPrice;
  const selectedPriceString = typeof selectedPrice === "string" ? selectedPrice : String(selectedPrice);
  const amount = parseFloat(selectedPriceString.replace(/\$/g, "")) || parseFloat(formData.price.replace(/\$/g, ""));
  
  
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState(""); // State for email input
  const [isFormValid, setIsFormValid] = useState(false);

  const handlePayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please fill in all required fields.",
      });
      return;
    }

    try {
      const response = await axios.post(
        `https://acadroof-backend.onrender.com/payment-intent`,
        {
          amount,
          currency: "Inr",
        }
      );


      if (response.status === 200) {
        const confirmPayment = await stripe.confirmCardPayment(
          response.data.client_secret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
          }
        );

        if (confirmPayment.paymentIntent.status === "succeeded") {
          // Show a success alert
          Swal.fire({
            icon: "success",
            title: "Payment Confirmed!",
            text: "Your payment has been successfully processed.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Payment Failed",
            text:
              "There was an issue processing your payment. Please try again later.",
          });
        }
      } else {
        // Handle server response errors
        Swal.fire({
          icon: "error",
          title: "Server Error",
          text: "There was an issue with the server. Please try again later.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred. Please try again later.",
      });
      console.error(error);
    }
  };
  
  const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: '2-digit', hour12: true };
    const time = new Date(`2000-01-01T${timeString}`);
    const formattedTime = time.toLocaleTimeString(undefined, options);
    return formattedTime.replace(/(\w{2})\s(\w{2})/, (_, am, pm) => `${am.toUpperCase()} ${pm.toUpperCase()}`);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // Check if email and other required fields are filled
    setIsFormValid(!!e.target.value && stripe && elements);
  };

  const elementStyle = {
    base: {
      fontSize: "16px",
      border: "1px solid #E2E8F0",
      borderRadius: "4px",
      bgColor: "#E2E8F0",
    },
    invalid: {
      color: "red",
    },
    placeholder: {
      color: "#666",
      fontSize: "16px",
      lineHeight: "24px",
    },
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const responsiveStyles = {
  base: {
    width: '100%',
  },
  md: {
    width: 'auto',
  },
};

  return (
    <form onSubmit={handlePayment}>
      <ChakraProvider>
        <Navbar />
      
        <motion.div
        
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.9 }}
        >
       <Box
            p={{lg:"10"}}
            mt={10}
            style={{ fontFamily: "Nunito Sans" }}
            background="linear-gradient(90deg, #bdc3c7, #2c3e50)"
          >
          <Heading as="h1" size="xl" mb={4} letterSpacing={10}  style={{ fontFamily: "Nunito Sans" }}>
            Make Payment
          </Heading>
          {/* <Text>Select Payment Method</Text> */}
        
          <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="stretch"
              mt={20}
            >
              <GridItem width={{ base: "100%", md: "45%",lg:"40%" }}>
                <Box
                  p={4}
                  borderRadius="12px"
                  border="1px solid #ccc"
                  boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                  position="relative"
                  background="linear-gradient(90deg, #000000 50%, #3e3e3e 50%)"
                >
     
    <Heading as="h2" size="md" mb={2} color="white" fontFamily="Nunito Sans">
      Payment Details
    </Heading>
    <div style={{ textAlign: "start" }}>
      <label htmlFor="email"  style={{ fontWeight: "bold", fontFamily: "Nunito Sans",color:"white" }}>
        Email
      </label>
      <Input
        id="email"
        bgColor="white"
        placeholder="Your email here"
        // mb={3}
        border="1px solid #E2E8F0"
        borderRadius="4px"
        value={formData.email || state.formData.senderEmail}
      />
    </div>
    {/* Add validation message */}
    {!isFormValid && (
      <Text color="red" fontSize="sm">
        {/* Please fill in all required fields. */}
      </Text>
    )}
    <div style={{ textAlign: "start" }}>
      <label htmlFor="cardNumber" style={{ fontWeight: "bold",color:"white" }}>
        Card number
      </label>

      <div
        style={{
          backgroundColor: "white",
          height: "40px",
          lineHeight: "24px",
          paddingTop: "8px",
          letterSpacing: "1px",
          paddingLeft: "6px",
          borderRadius: "4px",
          border: "1px solid #ccc",
          position: "relative",
        }}
      >
        <CardNumberElement options={{ style: elementStyle }} />
      </div>
    </div>
  
 
  <div style={{display:"flex" ,justifyContent:"space-around"}}>
  <div style={{ textAlign: "start" }}>
    <label htmlFor="expiryDate" style={{ fontWeight: "bold", color: "white" }}>
      Expiry Date
    </label>
    <div
      style={{
        backgroundColor: "white",
        height: "35px",
        lineHeight: "24px",
        paddingTop: "5px",
        paddingLeft: "10px",
        letterSpacing: "1px",
        borderRadius: "4px",
        border: "1px solid #ccc",
      }}
    >
      <CardExpiryElement options={{ style: elementStyle }} />
    </div>
  </div>
  <div style={{ textAlign: "start" }}>
    <label htmlFor="cvv" style={{ fontWeight: "bold", color: "white" }}>
      CVV
    </label>
    <div
      style={{
        backgroundColor: "white",
        height: "35px",
        paddingTop: "7px",
        width: "50px",
        paddingLeft: "6px",
        lineHeight: "24px",
        letterSpacing: "1px",
        borderRadius: "4px",
        border: "1px solid #ccc",
      }}
    >
      <CardCvcElement options={{ style: elementStyle }} />
    </div>
  </div>
  <div style={{ textAlign: "start" }}>
    <label htmlFor="cvv" style={{ fontWeight: "bold", color: "white", }}>
      Total Amount
    </label>
    <div
      style={{
        backgroundColor: "white",
        height: "35px",
        paddingTop: "5px",
        width: "100%", // Use full width on small screens
        paddingLeft: "6px",
        fontWeight: "700",
        borderRadius: "4px",
        border: "1px solid #ccc",
        display: "flex",
        justifyContent: "space-between", // Align the text and the amount
        alignItems: "center", // Center the text and the amount vertically
      }}
    >
      <Text mb={2} fontWeight="800" fontSize="18px">
        <span style={{ color: 'green' }}>$</span> <span style={{ color: 'green' }}>{state.formData.selectedPrice || formData.price}</span>
      </Text>
    </div>
  </div>
  </div>
  


    <div style={{ textAlign: "start", marginTop: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
      <Button
        type="submit"
        colorScheme="green"
        disabled={!isFormValid}
      >
        Make Payment
      </Button>
      <Image
      src="https://freepngimg.com/save/25654-major-credit-card-logo-transparent/797x130" // Replace with your Visa logo image
      alt="Visa"
      width={{base:"100px",lg:"250px"}}
      style={{
        // position: "absolute",
        bottom: "710px",
        right: "780px",
        // left:"-120px",
        // width: "250px",
        height: "auto",
      }}
    />
    </div>
  </Box>
</GridItem>

<GridItem width={{ base: "100%", md: "45%" }}>
  <Box
    display="flex"
    flexDirection="column"
    bg="gray.200"
    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
    borderWidth="1px"
    borderRadius="12px"
    overflow="hidden"
  >
    <Heading as="h2" size="md" mb={2} padding="20px" style={{fontFamily: 'Nunito Sans'}} color="white" backgroundColor="blue.500">
      Order Summary
    </Heading>

    <Box textAlign="start" p={{ lg: "30px" }}>
  <Table variant="striped" colorScheme="gray" style={{ fontFamily: 'Nunito Sans' }}>
    <Tbody>
      {state.name || formData.name ? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Name:</Td>
          <Td>{state.name || formData.name}</Td>
        </Tr>
      ) : null}
      {(formData.email || state.formData.senderEmail) ? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Email:</Td>
          <Td>{formData.email || state.formData.senderEmail}</Td>
        </Tr>
      ) : null}
      {state.topic || formData.topic ? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Topic:</Td>
          <Td>{state.topic || formData.topic}</Td>
        </Tr>
      ) : null}
      {state.csubject || formData.subject ? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Subject:</Td>
          <Td>{state.csubject || formData.subject}</Td>
        </Tr>
      ) : null}
      {state.formData.selectedDate ? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Date:</Td>
          <Td>{new Date(state.formData.selectedDate).toLocaleDateString()}</Td>
        </Tr>
      ) : null}
      {state.formData.selectedDeadlineDate ? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Deadline Date:</Td>
          <Td>{new Date(state.formData.selectedDeadlineDate).toLocaleDateString()}</Td>
        </Tr>
      ) : null}
      {state.timezone || formData.timezone ? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Time Zone:</Td>
          <Td>{state.timezone || formData.timezone}</Td>
        </Tr>
      ) : null}
      {state.formData.selectedPreferredTime ? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Preferred Time:</Td>
          <Td>{formatTime(state.formData.selectedPreferredTime)}</Td>
        </Tr>
      ) : null}
      {state.formData.selectedDeadlineTime ? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Deadline Time:</Td>
          <Td>{formatTime(state.formData.selectedDeadlineTime)}</Td>
        </Tr>
      ) : null}
      {state.subject || formData.selectedPlan ? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Selected plan:</Td>
          <Td>{state.subject || formData.selectedPlan}</Td>
        </Tr>
      ) : null}
      {state.message || formData.message || formData.purpose? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Additional Details:</Td>
          <Td>{state.message || formData.message || formData.purpose}</Td>
        </Tr>
      ) : null}
      {state.formData.selectedPrice || formData.price? (
        <Tr>
          <Td fontWeight="600" color="blue.500">Your Final Bill Amount:</Td>
          <Td>
            PlanPrice -{" "}
            <span style={{ color: "green", marginLeft: "8px" }}>
              ${state.formData.selectedPrice || formData.price} 
            </span>
          </Td>
        </Tr>
      ) : null}
    </Tbody>
  </Table>
</Box>

  </Box>
</GridItem>


  
          </Flex>
          
          
         
        
        </Box>
        <Divider
  orientation="horizontal"
  borderWidth="1px"
  marginTop={20}
  borderColor="#2c3e50" 
  width="100%" 
  my="-2"
/>



        <Footer  />
        </motion.div>
      
      </ChakraProvider>
    </form>
  );
};

export default PaymentPage;
