import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Text,
  Container,
  Flex,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { motion } from "framer-motion";

const PaymentForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    price: '',
    purpose: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    price: '',
    purpose: '',
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Check if all required fields are filled to enable the "Pay Now" button
    setIsFormValid(
      formData.name !== '' &&
        formData.email !== '' &&
        formData.price !== '' &&
        formData.purpose !== ''
    );
    window.scrollTo(0, 0);
  }, [formData]);

  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear the error message when the user starts typing in a field
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if any of the required fields are empty
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = 'This field is required';
      }
    });

    if (Object.keys(errors).length > 0) {
      // If there are errors, set the formErrors state to display them
      setFormErrors(errors);
    } else {
      // If no errors, proceed with form submission
      console.log(formData);
    }
  };

  return (
    <Container maxW="full">
      <Navbar/>
      <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.9 }}
      >
        <Text color="#1C5896" fontSize="3xl" mt="90px">
          Payment Details
        </Text>
     
      <Flex alignItems="center" justifyContent="start" ml={10}>
        <Link to="/">
          <Button fontFamily="Nunito Sans" colorScheme="blackAlpha" rounded="full">
            Back
          </Button>
        </Link>
      </Flex>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" borderRadius="lg">
        <Container>
          <Box borderWidth="1px" rounded="lg" boxShadow="2xl">
            <Box backgroundColor="#1C5896" h={20} borderTopRadius="lg">
              <Text
                textAlign="start"
                lineHeight="70px"
                fontSize="2xl"
                ml={4}
                width="full"
                color="white"
                style={{ fontFamily: 'Nunito Sans', fontWeight: '500' }}
              >
                Payment
              </Text>
            </Box>

            <form onSubmit={handleSubmit}>
        <VStack spacing={4} p={4} backgroundColor="#FAFAFA">
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="name"
              backgroundColor="#FFFFFF"
              value={formData.name}
              onChange={handleChange}
            />
            {formErrors.name && (
              <Text color="red" fontSize="sm">
                {formErrors.name}
              </Text>
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              backgroundColor="#FFFFFF"
              value={formData.email}
              onChange={handleChange}
            />
            {formErrors.email && (
              <Text color="red" fontSize="sm">
                {formErrors.email}
              </Text>
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel>
              Amount
              <span style={{ color: 'green' }}> (USD)</span>
            </FormLabel>
            <Input
              type="number"
              name="price"
              backgroundColor="#FFFFFF"
              value={formData.price}
              onChange={handleChange}
            />
            {formErrors.price && (
              <Text color="red" fontSize="sm">
                {formErrors.price}
              </Text>
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Purpose</FormLabel>
            <Input
              type="text"
              name="purpose"
              backgroundColor="#FFFFFF"
              value={formData.purpose}
              onChange={handleChange}
            />
            {formErrors.purpose && (
              <Text color="red" fontSize="sm">
                {formErrors.purpose}
              </Text>
            )}
          </FormControl>
          <Link to="/payment" state={{ formData }}>
            <Button colorScheme="blue" type="submit" isDisabled={!isFormValid}>
              Pay Now
            </Button>
          </Link>
        </VStack>
      </form>
          </Box>
        </Container>
      </Box>
      </motion.div>
      <Footer  />
    
    </Container>
  );
};

export default PaymentForm;
