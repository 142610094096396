// FourthModal.js
import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Text,
  VStack,
  Image,
  Box,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import mygif from "../assests/Gif/rad.gif";
import { motion } from "framer-motion";

function ConfirmationModal({
  isOpen,
  onClose,
  formData,
  onBookNow,
  selectedPrice,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.9 }}
      >
        <ModalContent
          maxW={{ base: "100%", md: "800px", lg: "500px" }}
          padding="10px"
          style={{
            background: "linear-gradient(315deg, #bdc3c7 0%, #2c3e50 100%)",
            top: "20%",
            left: "0%",
          }}
        >
          <ModalBody>
            <VStack spacing={4}>
              <Image src={mygif} width="30%" color="green" />
              <Text color="white" textAlign="center">
                Session Booked
              </Text>
              <Box display="flex" gap={20}>
                <Link to="/payment" state={{ formData, selectedPrice }}>
                  <Button
                    colorScheme="green"
                    onClick={() => onBookNow(formData)}
                  >
                    Pay Now
                  </Button>
                </Link>

                <Button colorScheme="blue" onClick={onClose}>
                  Pay later
                </Button>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </motion.div>
    </Modal>
  );
}

export default ConfirmationModal;
