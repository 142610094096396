import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Radio,
} from "@chakra-ui/react";
import ThirdModal from "./ThModal";
import './Subvar.css'

function SecondModal({ isOpen, onClose, apiData, onGoNext,subject,topic }) {
  
  const [selectedOption, setSelectedOption] = useState(null);
  const [showThirdModal, setShowThirdModal] = useState(false); // State to control ThirdModal visibility
  const [selectedPlan, setSelectedPlan] = useState({ planName: "", price: "" });

  const handleRadioChange = (option) => {
    setSelectedOption(option);
    const { planName, price } = apiData[option];
      setSelectedPlan({ planName, price });
      
  };

  const handleGoNext = () => {
    
    if (selectedOption && apiData[selectedOption]) {
      // const { planName, price } = apiData[selectedOption];
      // setSelectedPlan({ planName, price });
      // onGoNext(planName, price); 
      setShowThirdModal(true)
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}  >
        <ModalOverlay />
        <ModalContent maxW={{ base: "100%", md: "500px" }}  padding="10px"   backgroundColor="#F0F0F0" style={{ top: "20%", left: "0%" ,fontFamily: 'Nunito Sans'}}>
          <ModalHeader color="blue.500">Choose Plans</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            {apiData &&
              Object.keys(apiData).map((option) => (
                <Stack key={option} direction="row" alignItems="center" mb={2}>
                  <Radio

                    value={option}
                    isChecked={selectedOption === option}
                    onChange={() => handleRadioChange(option)}
                  >
              <span style={{ fontWeight: 'bold'  }}>    {apiData[option].planName}</span>  - <span style={{ fontWeight: 'bolder'  }}>${apiData[option].price}</span>
                  </Radio>
                </Stack>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleGoNext}>Go Next</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {showThirdModal && (
        <ThirdModal
       
        isOpen={showThirdModal}
        onClose={() => setShowThirdModal(false)}
        planName={selectedPlan.planName}
        price={selectedPlan.price}
       subject={subject}
       topic={topic}
    
        />
      )}
    </>
  );
}

export default SecondModal;
