import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import MyRoute from './Home_Routes/Route';
import './App.css';
import Portfolio from './components/Portfolio/Portfolio';
import Privacy from './Pages/Privacy Policy/Privacy';
import Terms from './Pages/Terms & Service/Terms';
import Payment from './components/paymentPage/Payment';
import Pay from './components/Pay/Pay';
import ChatButton from './components/ChatBox/Chatbutton'; 

const stripePromise = loadStripe('pk_test_hyeCtCBbBiQzKAwgWWAVs4mf00eLoZk8Sw');

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ChakraProvider>
         {/* Add the ChatButton component */}
          <Routes>
            
            <Route path="/" element={<MyRoute />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/pay" element={<Pay />} />
            
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            
            <Route path="/payment" element={<Elements stripe={stripePromise}><Payment /></Elements>} />
          </Routes>
          <ChatButton /> 
        </ChakraProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
