import React, { useState ,useRef,useEffect} from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Center,
  Button,
  Image,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import axios from "axios"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Rectangle from '../assests/PriceIMG/RectangleA.png';
import RectangleB from '../assests/PriceIMG/RectangleB.png';
import RectangleC from '../assests/PriceIMG/RectangleC.png';
import SecondModal from "./SecondModal";

const pricingPlans = [
  {
    name: "Prompt Support",
    price: "$60/hr",
    description: "Experience personalized learning and expert guidance with our one-on-one online tutoring sessions. Our qualified tutors provide customized instruction, interactive tools, and progress tracking to enhance your learning journey. Schedule a session today and unlock your full potential.",
    doneTexts: [
      "24/7 Availability",
      "Fast Response Time",
      "Expert Assistance",
      "Personalized Support",
      "Satisfaction Guaranteed",
    ],
    ModelText:[
    "Easy Scheduling: Our website offers a user-friendly interface that allows students to easily schedule one-on-one online tutoring sessions at their convenience.",
    "Interactive Virtual Classroom: Our online tutoring platform provides an interactive virtual classroom equipped with collaborative tools, such as a shared whiteboard, screen sharing, and chat features, enabling effective communication and real-time collaboration between the tutor and student.",
    "Progress Tracking: Our website offers a comprehensive progress tracking system that allows students and parents to monitor the student's performance, track milestones, and assess the effectiveness of the tutoring sessions.",
    "Regular Assessments: Our tutors conduct regular assessments to evaluate the student's understanding of the subject matter and identify any knowledge gaps, enabling targeted instruction and focused remediation.",
    "Reliable Technical Support: We provide reliable technical support to ensure a smooth online tutoring experience, addressing any technical issues or connectivity problems promptly, so that students can focus on their learning without interruptions."
    ],
    image: RectangleC,
  },
  {
    name: "One-on-one session",
    price: "$60/hr",
    description: "Experience personalized learning and expert guidance with our one-on-one online tutoring sessions. Our qualified tutors provide customized instruction, interactive tools, and progress tracking to enhance your learning journey. Schedule a session today and unlock your full potential.",
    doneTexts: [
      "Tailored Sessions",
      "Flexible Scheduling",
      "Hands-on Guidance",
      "Progress Tracking",
      "Knowledge Enrichment",
    ],
    ModelText:[
      "Easy Scheduling: Our website offers a user-friendly interface that allows students to easily schedule one-on-one online tutoring sessions at their convenience.",
      "Interactive Virtual Classroom: Our online tutoring platform provides an interactive virtual classroom equipped with collaborative tools, such as a shared whiteboard, screen sharing, and chat features, enabling effective communication and real-time collaboration between the tutor and student.",
      "Progress Tracking: Our website offers a comprehensive progress tracking system that allows students and parents to monitor the student's performance, track milestones, and assess the effectiveness of the tutoring sessions.",
      "Regular Assessments: Our tutors conduct regular assessments to evaluate the student's understanding of the subject matter and identify any knowledge gaps, enabling targeted instruction and focused remediation.",
      "Reliable Technical Support: We provide reliable technical support to ensure a smooth online tutoring experience, addressing any technical issues or connectivity problems promptly, so that students can focus on their learning without interruptions."
      ],
    image: RectangleB,
  },
  {
    name: "Assignment Help",
    price: "$40/hr",
    description: "Experience personalized learning and expert guidance with our one-on-one online tutoring sessions. Our qualified tutors provide customized instruction, interactive tools, and progress tracking to enhance your learning journey. Schedule a session today and unlock your full potential.",
    doneTexts: [
      "Expert Tutors",
      "Timely Deliveries",
      "Comprehensive Solutions",
      "Plagiarism-Free",
      "Concept Clarity",
    ],
    ModelText:[
      "Easy Scheduling: Our website offers a user-friendly interface that allows students to easily schedule one-on-one online tutoring sessions at their convenience.",
      "Interactive Virtual Classroom: Our online tutoring platform provides an interactive virtual classroom equipped with collaborative tools, such as a shared whiteboard, screen sharing, and chat features, enabling effective communication and real-time collaboration between the tutor and student.",
      "Progress Tracking: Our website offers a comprehensive progress tracking system that allows students and parents to monitor the student's performance, track milestones, and assess the effectiveness of the tutoring sessions.",
      "Regular Assessments: Our tutors conduct regular assessments to evaluate the student's understanding of the subject matter and identify any knowledge gaps, enabling targeted instruction and focused remediation.",
      "Reliable Technical Support: We provide reliable technical support to ensure a smooth online tutoring experience, addressing any technical issues or connectivity problems promptly, so that students can focus on their learning without interruptions."
      ],
    image: Rectangle,
  },
];

function PricingPage() {
  const [showModalIndex, setShowModalIndex] = useState(null);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [apiData, setApiData] = useState(null);
  useEffect(() => {
    axios
      .get("https://acadroof-backend.onrender.com/getPrices")
      .then((response) => {
        setApiData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  }, []);
  

  const openModal = (index) => {
    setShowModalIndex(index);
  };

  const closeModal = () => {
    setShowModalIndex(null);
  };

  const openSecondModal = () => {
    setShowSecondModal(true);
  };

  const closeSecondModal = () => {
    setShowSecondModal(false);
  };


  const handleScroll = () => {
    // document.getElementById('subject-section').scrollIntoView({ behavior: 'smooth',});
    closeModal();
    openSecondModal();
  };

  return (
    <Box p={{ base: "20px", md: "60px" }} backgroundColor="#2F9AE6" mt="20px" id="pricing-section">
      <Heading color="white"  style={{ fontFamily: "Nunito Sans" }} mb={4} fontSize={{ base: "2xl", md: "3xl" }}>
        Pricing
      </Heading>
      <Text  style={{ fontFamily: "Nunito Sans" }} color="white" fontSize={{ base: "sm", md: "md" }}>
        Our pricing is competitive and transparent, reflecting our commitment to<br/>
        providing affordable and accessible services.
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={8}>
        {pricingPlans.map((plan, index) => (
          <Center
            key={index}
            border="1px"
            backgroundColor="white"
            borderColor="gray.200"
            margin="auto"
            borderRadius="lg"
            mt={10}
            p={4}
            flexDirection="column"
            width="80%"
            boxShadow="0px 5px 16px 0px #143BA433"
          >
            <Image
              src={plan.image}
              alt={`Pricing Plan ${plan.name} Image`}
              boxSize="100px"
              objectFit="cover"
              mb={2}
            />
            <Text fontSize="xl" fontWeight="semibold" mb={2}  style={{ fontFamily: "Nunito Sans" }}>
              {plan.name}
            </Text>
            <Text  style={{ fontFamily: "Nunito Sans" }} fontSize="2xl">{plan.price}</Text>
            <Box mt={2} textAlign="start" paddingBottom={3}>
              {plan.doneTexts.map((text, i) => (
                <Text key={i}  style={{ fontFamily: "Nunito Sans" }} color="green.500" fontWeight="bold">
                  <span style={{ color: "#2F9AE6", marginRight: "5px" }}>
                    &#10003;
                  </span>{" "}
                  {text}
                </Text>
              ))}
            </Box>
            <Button
              w="80%"
              colorScheme="blue"
              mt={4}
              style={{ fontFamily: "Nunito Sans" }}
              onClick={() => openModal(index)}
            >
              Book Now
            </Button>
            {/* <Link mt={4} textDecoration="underline">
              Learn more
            </Link> */}
          </Center>
        ))}
      </SimpleGrid>

      {pricingPlans.map((plan, index) => (
        <Modal
          isOpen={showModalIndex === index}
          onClose={closeModal}
          key={index}
        >
          <ModalOverlay />
          <ModalContent maxW={{ base: "100%", md: "800px" }} padding="10px"   bg="#F0F0F0">
            <ModalHeader>{plan.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {plan.ModelText.map((text, i) => {
                const parts = text.split(": ");
                return (
                  <Text key={i}>
                    <span style={{ color: "#2F9AE6", marginRight: "5px" }}>
                      {" "}
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    <span style={{ fontWeight: "bold" }}>{parts[0]}:</span>{" "}
                    {parts.slice(1).join(": ")}
                  </Text>
                );
              })}
              <Heading textAlign="center">{plan.price}</Heading>
              <Center>
                <Button onClick={handleScroll} colorScheme="blue" mt={4}>
                  Book My Session
                </Button>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick={closeModal}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ))}
        <SecondModal isOpen={showSecondModal} onClose={closeSecondModal} apiData={apiData} />
    </Box>
  );
}


export default PricingPage;