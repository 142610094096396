// SecondModal.js
import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Radio,
} from "@chakra-ui/react";
import ThirdModal from "./ThirdModal"; // Import the ThirdModal component

function SecondModal({ isOpen, onClose, apiData }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showThirdModal, setShowThirdModal] = useState(false); // State to control ThirdModal visibility
  const [selectedPlan, setSelectedPlan] = useState({ planName: "", price: "" });

  const handleRadioChange = (option) => {
    setSelectedOption(option);
  };

  const handleGoNext = () => {
    if (selectedOption) {
      // Find the selected planName and price from apiData
      const { planName, price } = apiData[selectedOption];
      setSelectedPlan({ planName, price });
      setShowThirdModal(true); // Open the ThirdModal
      onClose(); // Close the second modal
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "100%", md: "500px" }} backgroundColor="#F0F0F0"  padding="10px" style={{ top: "20%", left: "0%" ,    fontFamily: 'Nunito Sans',}}>
        <ModalHeader color="blue.500">Choose Plans</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {apiData &&
              Object.keys(apiData).map((option) => (
                <Stack key={option} direction="row" alignItems="center" mb={2}>
                  <Radio
                    value={option}
                    isChecked={selectedOption === option}
                    onChange={() => handleRadioChange(option)}
                  >
 <span style={{ fontWeight: 'bold'  }}>    {apiData[option].planName}</span>  - <span style={{ fontWeight: 'bolder'  }}>${apiData[option].price}</span>
                  </Radio>
                </Stack>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleGoNext}>Go Next</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {showThirdModal && (
        <ThirdModal
          isOpen={showThirdModal}
          onClose={() => setShowThirdModal(false)}
          planName={selectedPlan.planName}
          price={selectedPlan.price}
        />
      )}
    </>
  );
}

export default SecondModal;
