import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Input,
  Select,
  Box,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Image,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import ConfirmationModal from "./ConfirmationModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ThirdModal({ isOpen, onClose, planName, price, subject, topic }) {
  console.log("Subject prop:", subject); // Add this line to log the subject prop
  console.log("Topic prop:", topic);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    toEmail: "coderoofitsolutions@gmail.com",
    senderEmail: "",
    subject: subject || "", // Initialize with prop value
    csubject: subject || "", // Initialize with prop value
    topic: topic || "", // Initialize with prop value
    selectedDate: "",
    timezone: "",
    selectedPreferredTime: "",
    selectedDeadlineDate: "",
    selectedDeadlineTime: "",
    selectedPlan: planName,
    selectedPrice: price,
    message: "",
    selectedFile: null,
  });
  // Set 'subject' and 'csubject' to an initial value
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    // Check if all required fields are filled
    const isValid =
      formData.name &&
      formData.senderEmail &&
      formData.subject &&
      formData.csubject &&
      formData.topic &&
      formData.selectedDate &&
      formData.timezone &&
      formData.selectedPreferredTime &&
      formData.selectedDeadlineDate &&
      formData.selectedDeadlineTime &&
      formData.message;

    setIsFormValid(isValid);
  }, [formData]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      subject: subject || "",
      csubject: subject || "",
    }));
  }, [subject]);
  // This useEffect runs when 'subject' prop changes

  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    if (isOpen) {
      axios
        .get("https://acadroof-backend.onrender.com/subjects")
        .then((response) => {
          setSubjects(response.data);
        })
        .catch((error) => {
          console.error("Error fetching subjects:", error);
        });
    }
  }, [isOpen]);

  const handleBookNow = (formData) => {
    // You can use formData to send the collected data to your booking logic or API
    // For now, we'll just close the confirmation modal
    setShowConfirmationModal(false);
  };

  const fetchTopics = (selectedSubject) => {
    const selectedSubjectData = subjects.find(
      (subject) => subject.name === selectedSubject
    );
    if (selectedSubjectData) {
      setTopics(selectedSubjectData.descriptions);
    } else {
      setTopics([]);
    }
  };

  const handleSubmit = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("senderEmail", formData.senderEmail);
      formDataToSend.append("subject", formData.subject);
      formDataToSend.append("csubject", formData.csubject);
      formDataToSend.append("topic", formData.topic);
      formDataToSend.append("selectedDate", formData.selectedDate);
      formDataToSend.append(
        "selectedDeadlineDate",
        formData.selectedDeadlineDate
      );
      formDataToSend.append(
        "selectedDeadlineTime",
        formData.selectedDeadlineTime
      );
      formDataToSend.append("timezone", formData.timezone);
      formDataToSend.append("file", formData.selectedFile);
      formDataToSend.append("message", formData.message);
      formDataToSend.append(
        "selectedPreferredTime",
        formData.selectedPreferredTime
      );
      formDataToSend.append("selectedPrice", formData.selectedPrice);
      formDataToSend.append("selectedPlan", formData.selectedPlan);

      const response = await axios.post(
        "https://acadroof-backend.onrender.com/sendEmail",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type for FormData
          },
        }
      );
      console.log(response.data.message);
      toast.success("Email sent successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // onClose();
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleRemoveFile = () => {
    setFormData({ ...formData, selectedFile: null });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Changing ${name} to ${value}`);
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "subject") {
      fetchTopics(value);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024); // Calculate file size in MB
      if (fileSizeInMB <= 20) {
        // If file size is within the limit, set it in formData
        setFormData({
          ...formData,
          selectedFile: selectedFile,
        });
      } else {
        // If file size exceeds the limit, show a Toastify alert
        toast.error("File size exceeds 20MB limit.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} selectedPlan maxH="full">
        <ToastContainer />
        <ModalOverlay />
        <ModalContent
          maxW={{ base: "100%", md: "800px", lg: "auto" }}
          padding="10px"
          maxH="auto"
          bg="gray.500"
          style={{ fontFamily: "Nunito Sans" }}
        >
          <ModalHeader fontWeight="700" color="blue.500"  style={{ fontFamily: "Nunito Sans" }}>
            Register Details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody maxHeight="70vh" overflowY="auto">
            <VStack spacing={4} width="100%">
              <HStack spacing={4} width="100%"  style={{ fontFamily: "Nunito Sans" }}>
                <FormControl>
                  <FormLabel fontWeight="bold">Name</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    bgColor="gray.400"
                    required
                    placeholder="Enter your name"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel pb={5} fontWeight="bold">
                    Your Selected Plan
                  </FormLabel>
                  <HStack bgColor="gray.400" height={0}>
                    <Text
                      fontSize={{ base: "x-small", lg: "medium" }}
                      style={{
                        // background: "linear-gradient(315deg, #dce35b 0%, #45b649 100%)",
                        WebkitBackgroundClip: "text",
                        color: "green",
                        fontWeight: 700,
                        // padding:"20px"
                      }}
                    >
                      {planName}
                    </Text>
                    <Text ml="2" fontWeight="600">
                      Price:{" "}
                      <strong
                        style={{
                          // background: "linear-gradient(315deg, #dce35b 0%, #45b649 100%)",
                          WebkitBackgroundClip: "text",
                          color: "green",
                          fontWeight: 700,
                          // padding:"20px"
                        }}
                      >
                        ${price}
                      </strong>{" "}
                    </Text>
                  </HStack>
                </FormControl>
              </HStack>
              <HStack spacing={4} width="100%">
                <FormControl>
                  <FormLabel fontWeight="bold">Email</FormLabel>
                  <Input
                    type="email"
                    name="senderEmail"
                    bgColor="gray.400"
                    value={formData.senderEmail}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    required
                  />
                </FormControl>
                {/* <Text>{subject}</Text>
     <Text>{topic}</Text> */}
                <FormControl>
                  <FormLabel fontWeight="bold">Timezone</FormLabel>
                  <Select
                    name="timezone"
                    bgColor="gray.400"
                    value={formData.timezone}
                    onChange={handleChange}
                    placeholder="Select a timezone"
                    required
                  >
                    <option value="Eastern Standard Time">
                      (GMT-05:00) Eastern Standard Time
                    </option>
                    <option value="Central Standard Time">
                      (GMT-06:00) Central Standard Time
                    </option>
                    <option value="Pacific Standard Time">
                      (GMT-08:00) Pacific Standard Time
                    </option>
                    <option value="Mountain Standard Time">
                      (GMT-07:00) Mountain Standard Time
                    </option>
                  </Select>
                </FormControl>
              </HStack>
              <HStack spacing={4} width="100%">
                <FormControl flex="1">
                  <FormLabel fontWeight="bold">Subject</FormLabel>
                  <Select
                    name="subject"
                    bgColor="gray.400"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    // placeholder="Select a subject"
                  >
                    <option value={subject}>{subject}</option>
                    {/* Render the subjects as options */}
                    {subjects.map((subjectOption) => (
                      <option
                        key={subjectOption._id}
                        value={subjectOption.name}
                      >
                        {subjectOption.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl flex="1">
                  <FormLabel fontWeight="bold">Topic</FormLabel>
                  <Select
                    name="topic"
                    bgColor="gray.400"
                    value={formData.topic}
                    onChange={handleChange}
                    required
                    // placeholder="Select a topic"
                  >
                    <option value={topic}>{topic}</option>
                    {/* Render the topics as options */}
                    {topics.map((topicOption) => (
                      <option key={topicOption} value={topicOption}>
                        {topicOption}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </HStack>

              <FormControl>
                <FormLabel fontWeight="bold">Preferred Date & Time</FormLabel>
                <HStack spacing={4}>
                  <Input
                    type="date"
                    name="selectedDate"
                    bgColor="gray.400"
                    flex="1"
                    required
                    value={formData.selectedDate}
                    onChange={handleChange}
                  />
                  <Input
                    type="time"
                    name="selectedPreferredTime"
                    flex="1"
                    bgColor="gray.400"
                    required
                    value={formData.selectedPreferredTime}
                    onChange={handleChange}
                  />
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel fontWeight="bold">Deadline Date & Time</FormLabel>
                <Flex gap={5}>
                  <Input
                    type="date"
                    bgColor="gray.400"
                    required
                    name="selectedDeadlineDate"
                    value={formData.selectedDeadlineDate}
                    onChange={handleChange}
                  />
                  <Input
                    type="time"
                    bgColor="gray.400"
                    name="selectedDeadlineTime"
                    required
                    value={formData.selectedDeadlineTime}
                    onChange={handleChange}
                  />
                </Flex>
              </FormControl>
              <HStack spacing={4} width="100%">
                <FormControl>
                  <FormLabel fontWeight="bold">Additional Details</FormLabel>
                  <Textarea
                    name="message"
                    bgColor="gray.400"
                    required
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Enter your message"
                  />
                </FormControl>
                {formData.selectedFile && (
                  <FormControl>
                    <FormLabel fontWeight="bold">Uploaded Picture</FormLabel>
                    <Flex align="center">
                      <Box>
                        <Image
                          src={URL.createObjectURL(formData.selectedFile)}
                          alt="Uploaded Picture"
                          boxSize="40px"
                          objectFit="cover"
                        />
                      </Box>
                      <Button
                        // variant="outline"
                        colorScheme="blackAlpha"
                        size="sm"
                        backgroundColor="grey.300"
                        ml="2"
                        onClick={handleRemoveFile}
                      >
                        Remove
                      </Button>
                    </Flex>
                  </FormControl>
                )}
                {!formData.selectedFile && (
                  <FormControl>
                    <FormLabel fontWeight="bold">Upload File</FormLabel>
                    <Input
                      type="file"
                      name="file"
                      accept="*"
                      height="20"
                      style={{ backgroundColor: "#CBD5E0" }}
                      color="white"
                      onChange={handleFileChange}
                    />
                  </FormControl>
                )}
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              margin="auto"
              width="90%"
              onClick={() => {
                if (isFormValid) {
                  handleSubmit();
                  setShowConfirmationModal(true);
                } else {
                  toast.error("Please fill in all required fields.");
                }
              }}
              colorScheme="blue"
              isDisabled={!isFormValid}
            >
              Book My Session
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          formData={formData}
          selectedPrice={price}
          onBookNow={handleBookNow}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
}

export default ThirdModal;
