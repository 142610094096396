const serviceData = [
    {
      heading: "Service 1 Heading",
      paragraph: "Service 1 Paragraph",
      imageLeft: require("../assests/ServicesImg/serB.png"),
      imageRight: require("../assests/ServicesImg/serA.png"),
      imageLeftDescription: "Left Service 1 Illustration",
      leftImageHeading: "Prompt Support",
      leftImageParagraph: "Online instant help provides fast and convenient assistance\nto individuals in need of support.With just a few clicks, users can \nconnect with trained professionals and receive immediate guidance\non a range of topics.",
    },
    {
      heading: "Service 2 Heading",
      paragraph: "Service 2 Paragraph",
      imageLeft: require("../assests/ServicesImg/Affordable.png"),
      imageRight: require("../assests/ServicesImg/Afs.png"),
      imageLeftDescription: "Left Service 2 Illustration",
      leftImageHeading: "Affordable pricing",
      leftImageParagraph: "You get premium service at the best market price. The cost of\n our quality products does not make hole in your pockets. We are \naffordable and make sure that you get your money’s worth",
    },
    {
        heading: "Service 3 Heading",
        paragraph: "Service 2 Paragraph",
        imageLeft: require("../assests/ServicesImg/Recd.png"),
        imageRight: require("../assests/ServicesImg/Privacyd 1.png"),
        imageLeftDescription: "Left Service 2 Illustration",
        leftImageHeading: "Privacy Guaranteed",
        leftImageParagraph: "At our services, we prioritize your privacy above all else. We\n understand the importance of safeguarding your personal identity\n and materials, which is why we take extensive measures to ensure\n complete security.",
      },
       {
      heading: "Service 4 Heading",
      paragraph: "Service 4 Paragraph",
      imageLeft: require("../assests/ServicesImg/Plag.png"),
      imageRight: require("../assests/ServicesImg/Rect.png"),
      imageLeftDescription: "Left Service 2 Illustration",
      leftImageHeading: "Plagiarism-free",
      leftImageParagraph: "As a team, we are highly skilled and proficient in our work. Our \ndedication and commitment to delivering top-notch service is\n matched by our guarantee of originality. We are confident\n in our ability to produce unique and plagiarism-free work.",
    },
    {
        heading: "Service 5 Heading",
        paragraph: "Service 5 Paragraph",
        imageLeft: require("../assests/ServicesImg/ta.png"),
        imageRight: require("../assests/ServicesImg/da.png"),
        imageLeftDescription: "Left Service 2 Illustration",
        leftImageHeading: "Experienced Tutors",
        leftImageParagraph: "Our tutors are graduates from prestigious universities, with strong\n backgrounds in computer science and programming languages. You\n can trust their expertise to provide exceptional guidance and\n support in your learning journey.",
      },
      {
        heading: "Service 6 Heading",
        paragraph: "Service 6 Paragraph",
        imageLeft: require("../assests/ServicesImg/gs.png"),
        imageRight: require("../assests/ServicesImg/ks.png"),
        imageLeftDescription: "Left Service 2 Illustration",
        leftImageHeading: "Wide Range of Subjects",
        leftImageParagraph: "We have a wide range of expertise and can cover almost every\n computer science project. Whatever subject you need help\n with,we are confident that our team can provide you with the \nnecessary assistance.",
      },
      {
        heading: "Service 7 Heading",
        paragraph: "Service 7 Paragraph",
        imageLeft: require("../assests/ServicesImg/Rs.png"),
        imageRight: require("../assests/ServicesImg/dl.png"),
        imageLeftDescription: "Left Service 2 Illustration",
        leftImageHeading: "On Time Delivery",
        leftImageParagraph: "We never compromise when it comes to deadlines and\n the delivery of assignments on time. Our team make sure\n that all orders are submitted prior to the deadline.",
      },
    // Add more service objects here
  ];
  
  export default serviceData;
  